<template>
  <!-- COMENTADO POR WILL -->
  <!-- v-if="mixing" -->

  <div class="navbar-container d-flex content align-items-center" v-if="mixing">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div> -->

    <!-- COMENTADO POR WILL -->
    <div
      class="bookmark-wrapper align-items-left flex-grow-1 d-none d-lg-flex mx-1"
    >
      <label class="nav-link nav-link-search">
        <strong>OPERACION: </strong>
        <span v-if="mixing.apertura == false && mixing.corte == false"
          >SIN INICIAR</span
        >
        <span
          class="text-primary"
          v-if="mixing.apertura == true && mixing.corte == false"
          >INICIADO</span
        >
        <span class="text-danger" v-if="mixing.corte">CERRADA</span>
      </label>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item>
        <dark-Toggler />
      </b-nav-item>
      <b-overlay :show="loading">
        <!-- @click="cambiarEstado" -->
        <b-nav-item
          v-if="mixing.realizarFacturacion"
          style="border-right: 1px solid #d6dce1; padding: 0.5rem 0"
        >
          <span class="ml-50 text-body mr-50 d-none d-md-inline-block"
            >FACTURACION</span
          >
          <!--  v-if="estadoSistema" -->
          <feather-icon size="21" icon="WifiIcon" class="text-success" />
          <!-- <feather-icon
            v-else
            size="21"
            icon="WifiOffIcon"
            class="text-danger"
          /> -->
        </b-nav-item>
      </b-overlay>

      <b-nav-item style="border-right: 1px solid #d6dce1; padding: 0.5rem 0">
        <b-form-select
          style="width: 175px"
          v-model="sede_id"
          :options="mixing.sedes"
          size="sm"
          @change="cambiarSede"
        />
      </b-nav-item>

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ mixing.nombre }}
            </p>
            <span class="user-status">Online</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/100-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="14" icon="UserIcon" class="mr-50" />
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <!-- COMENTADO POR WILL -->
        <b-dropdown-item
          @click.prevent="signOut"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Cerrar Session</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store/index";
import axios from "axios";
import SedeServices from "@/modules/configuracion/sede/services/index.js";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BOverlay,
  BNavItem,
  BFormSelect,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BOverlay,
    BNavItem,
    BFormSelect,
    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      loading: false,
      estadoSistema: true,
      sedeItems: [],
      sede_id: this.$store.state.auth.sede_id,
      realizarFacturacion: false,
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    ...mapActions({
      signOutActions: "auth/signOut",
      peticionCambiarSede: "auth/peticionCambiarSede",
    }),
    validarActualizarUser() {
      this.loading = true;
      if (localStorage.getItem("token")) {
        this.actualizarUser();
      } else {
        this.loading = false;
        this.$router.push({ name: "login" });
      }
    },
    async actualizarUser() {
      this.loading = true;
      await axios
        .get(`${process.env.VUE_APP_FACT_REST}/facturacion/empresas/isonline`, {
          headers: {
            "Content-Type": "application/json",
            apikey: `${process.env.VUE_APP_APIKEY}`,
          },
        })
        .then((res) => {
          if (res.data) {
            if (res.data.isOnline) {
              this.estadoSistema = res.data.isOnline;
            } else {
              this.estadoSistema = res.data.isOnline;
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    signOut() {
      this.signOutActions()
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          console.log("error");
        });
    },
    cambiarEstado() {
      let mensaje = this.estadoSistema
        ? "ESTA SEGURO DE CAMBIAR A OFFLINE"
        : "ESTA SEGURO DE CAMBIAR A ONLINE";
      this.$bvModal
        .msgBoxConfirm(mensaje, {
          title: "CAMBIAR ESTADO ?",
          size: "sm",
          buttonSize: "sm",
          okVariant: this.estadoSistema ? "danger" : "primary",
          okTitle: "Si, Cambiar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.cambiarEstadoSistema();
          }
        });
    },
    async cambiarEstadoSistema() {
      this.loading = true;
      await axios
        .post(
          `${process.env.VUE_APP_FACT_REST}/facturacion/empresas/manual`,
          {
            manual: this.estadoSistema,
          },
          {
            headers: {
              "Content-Type": "application/json",
              apikey: `${process.env.VUE_APP_APIKEY}`,
            },
          }
        )
        .then((res) => {
          this.estadoSistema = !this.estadoSistema;
          this.loading = false;
          this.$bvToast.toast("Cambio de estado con exito", {
            title: "EXITO!",
            variant: "primary",
            solid: false,
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$bvToast.toast("Cambio de estado con fallido", {
            title: "ADVERTENCIA!",
            variant: "danger",
            solid: false,
          });
        });
    },
    async getSedes() {
      try {
        const response = await SedeServices.getSedeUsuario({});
        this.sedeItems = response.data.map((g) => ({
          value: g.id,
          text: `${g.municipio.departamento.nombre} ${g.municipio.nombre} ${g.sucursal.numero_sucursal} - ${g.numero_sede}`,
        }));
      } catch (err) {
        this.loading = false;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    cambiarSede() {
      this.peticionCambiarSede({
        sede_id: this.sede_id,
      })
        .then(() => {
          this.$bvToast.toast("Sede cambiada", {
            title: "EXITO!",
            variant: "success",
            solid: false,
          });

          setTimeout(() => {
            window.location.reload();
          }, 200);
        })
        .catch((err) => {
          this.loading = false;
          this.$bvToast.toast(err, {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        });
    },
  },
  mounted() {
    // if (this.mixing.realizarFacturacion) {
    //   this.actualizarUser();
    // }
    // this.getSedes();
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}
</style>