export default [
  {
    title: "Escritorio",
    route: "escritorio",
    icon: "HomeIcon",
    slug: "escritorio",
  },
  {
    header: "MODULO DE INVENTARIO",
    slug: "producto-ver;categoria-ver;marca-ver;ubicacion-ver;ajuste_positivo-ver;ajuste_positivo-registrar;ajuste_negativo-ver;ajuste_negativo-registrar;inventario_inicial-ver;inventario_inicial-registrar",
  },
  {
    title: "Productos",
    icon: "CoffeeIcon",
    slug: "producto-ver;categoria-ver;marca-ver;ubicacion-ver",
    children: [
      {
        title: "Productos",
        route: "administracion-productos",
        slug: "producto-ver",
      },
      {
        title: "Categorias",
        route: "administracion-categorias",
        slug: "categoria-ver",
      },
      {
        title: "Linea - Marcas",
        route: "administracion-marcas",
        slug: "marca-ver",
      },
      {
        title: "Areas - Ubicaciones",
        route: "administracion-ubicaciones",
        slug: "ubicacion-ver",
      },
      {
        title: "Promociones",
        route: "administracion-promociones",
        slug: "promocion-ver",
      },
    ],
  },
  {
    title: "Invetario Inicial",
    icon: "FileTextIcon",
    slug: "inventario_inicial-ver;inventario_inicial-registrar",
    children: [
      {
        title: "Todos los Inventario Inicial",
        route: "inventario-inicial",
        slug: "inventario_inicial-ver",
      },
      {
        title: "Nuevo Inventario Inicial",
        route: "inventario-inicial-nuevo",
        slug: "inventario_inicial-registrar",
      },
    ],
  },
  {
    title: "Ajuste de Inv. Positivo",
    icon: "PlusIcon",
    slug: "ajuste_positivo-ver;ajuste_positivo-registrar",
    children: [
      {
        title: "Todos Ajustes Positivos",
        route: "ajuste-positivo",
        slug: "ajuste_positivo-ver",
      },
      {
        title: "Nuevo Ajuste Positivo",
        route: "ajuste-positivo-nuevo",
        slug: "ajuste_positivo-registrar",
      },
    ],
  },
  {
    title: "Ajuste de Inv. Negativo",
    icon: "MinusIcon",
    slug: "ajuste_negativo-ver;ajuste_negativo-registrar",
    children: [
      {
        title: "Todos Ajustes Negativos",
        route: "ajuste-negativo",
        slug: "ajuste_negativo-ver",
      },
      {
        title: "Nuevo Ajuste Negativo",
        route: "ajuste-negativo-nuevo",
        slug: "ajuste_negativo-registrar",
      },
    ],
  },
  {
    header: "CLIENTES Y PROVEEDORES",
    slug: "cliente-ver;proveedor-ver",
  },
  {
    title: "Clientes",
    route: "administracion-clientes",
    icon: "UserIcon",
    slug: "cliente-ver",
  },
  {
    title: "Proveedores",
    route: "administracion-proveedores",
    icon: "UserCheckIcon",
    slug: "proveedor-ver",
  },

  {
    header: "MODULO DE VENTAS",
    slug: "venta-ver;venta-registrar",
  },
  {
    title: "Todas las Ventas",
    route: "ventas",
    icon: "FileTextIcon",
    slug: "venta-ver",
  },
  {
    title: "Nueva Venta",
    route: "ventas-punto-venta",
    icon: "ShoppingCartIcon",
    slug: "venta-registrar",
  },

  {
    header: "MODULO DE FACTURACION",
    slug: "factura-ver",
  },
  {
    title: "Todas las Facturas",
    route: "facturas",
    icon: "FileTextIcon",
    slug: "factura-ver",
  },
  {
    title: "Nueva Factura",
    route: "facturas-nueva-factura",
    icon: "InboxIcon",
    slug: "factura-registrar",
  },
  {
    header: "MODULO DE COMPRAS",
    slug: "compra-ver;compra-registrar",
  },
  {
    title: "Todas las Compras",
    route: "compras",
    icon: "FileTextIcon",
    slug: "compra-ver",
  },
  {
    title: "Nueva Compra",
    route: "compras-nueva-compra",
    icon: "TruckIcon",
    slug: "compra-registrar",
  },

  {
    header: "MODULO DE COTIZACIONES",
    slug: "cotizacion-ver;cotizacion-registrar",
  },
  {
    title: "Todas las Cotizaciones",
    route: "cotizaciones",
    icon: "FileTextIcon",
    slug: "cotizacion-ver",
  },
  {
    title: "Nueva Cotización",
    route: "cotizaciones-nueva-cotizacion",
    icon: "FileMinusIcon",
    slug: "cotizacion-registrar",
  },

  {
    header: "MODULO DE PEDIDOS",
    slug: "pedido-ver;pedido-registrar",
  },
  {
    title: "Todos los Pedidos",
    route: "pedidos",
    icon: "FileTextIcon",
    slug: "pedido-ver",
  },
  {
    title: "Nuevo Pedido",
    route: "pedidos-nuevo-pedido",
    icon: "InboxIcon",
    slug: "pedido-registrar",
  },

  {
    header: "PAGOS",
    slug: "cuentas_cobrar-ver;cuentas_pagar-ver",
  },
  {
    title: "Cuentas por Cobrar",
    route: "pagos.cuenta-cobrar",
    icon: "DollarSignIcon",
    slug: "cuentas_cobrar-ver",
  },
  {
    title: "Cuentas por Pagar",
    route: "pagos.cuenta-pagar",
    icon: "DollarSignIcon",
    slug: "cuentas_pagar-ver",
  },

  {
    header: "MODULO DE TRANSF. Y RECEPCIONES",
    slug: "transferencia-ver;transferencia-registrar;recepcion-ver",
  },
  {
    title: "Todas las Transferencias",
    route: "transferencias",
    icon: "FileTextIcon",
    slug: "transferencia-ver",
  },
  {
    title: "Nueva Transferencia",
    route: "transferencias-nueva-transferencia",
    icon: "ArrowUpCircleIcon",
    slug: "transferencia-registrar",
  },
  {
    title: "Todas las Recepciones",
    route: "recepciones",
    icon: "FileTextIcon",
    slug: "recepcion-ver",
  },

  {
    header: "GESTION DE OPERACIONES (CAJA)",
    slug: "operacion-gestion",
  },
  {
    title: "Gestion de Operaciones",
    route: "gestion.operaciones",
    icon: "MonitorIcon",
    slug: "operacion-gestion",
  },

  {
    header: "MODULO DE CONSULTAS Y REPORTES",
    slug: "reportes_inventarios-ver;reportes_compras-ver;reportes_ventas-ver;reporte_utilidad-ver;reportes_usuario-ver",
  },
  {
    title: "Reportes de Inventario",
    icon: "BarChart2Icon",
    slug: "reportes_inventarios-ver",
    children: [
      {
        title: "Entrada por compras",
        route: "reportes-inventario-compras",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "Salida por ventas",
        route: "reportes-inventario-ventas",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "Catalogo",
        route: "reportes-inventario-catalogo",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "Existencia",
        route: "reportes-inventario-existencia",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "Inventario General",
        route: "reportes-inventario-inventario-general",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "Inv. por Linea-Marca",
        route: "reportes-inventario-linea-marca",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "Inv. por Categoria",
        route: "reportes-inventario-categoria",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "Movimiento General",
        route: "reportes-inventario-movimiento-general",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "Mov. General Usuario",
        route: "reportes-inventario-movimiento-general-usuario",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "kardex",
        route: "reportes-inventario-movimiento-kardex",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "kardex por fechas",
        route: "reportes-inventario-movimiento-kardex-fechas",
        slug: "reportes_inventarios-ver",
      },
      {
        title: "Vencimientos",
        route: "reportes-inventario-vencimientos",
        slug: "reportes_inventarios-ver",
      },
    ],
  },
  {
    title: "Reportes de Compras",
    icon: "BarChart2Icon",
    slug: "reportes_compras-ver;reportes_usuario-ver",
    children: [
      {
        title: "Compras Generales",
        route: "reportes-compras-generales",
        slug: "reportes_compras-ver",
      },
      {
        title: "Compras Detalladas",
        route: "reportes-compras-detalladas",
        slug: "reportes_compras-ver",
      },
      {
        title: "Compras Det. por Usuario",
        route: "reportes-compras-detalladas-por-usuario",
        slug: "reportes_compras-ver",
      },
      {
        title: "Compras por Proveedor",
        route: "reportes-compras-detalladas-por-proveedor",
        slug: "reportes_compras-ver",
      },
      {
        title: "Compras Totales Mensuales",
        route: "reportes-compras-totales-mensuales",
        slug: "reportes_compras-ver",
      },
      {
        title: "Compras por Usuario",
        route: "reportes-compras-por-usuario",
        slug: "reportes_usuario-ver",
      },
    ],
  },
  {
    title: "Reportes de Ventas",
    icon: "BarChart2Icon",
    slug: "reportes_ventas-ver;reportes_usuario-ver",
    children: [
      {
        title: "Ventas Generales",
        route: "reportes-ventas-generales",
        slug: "reportes_ventas-ver",
      },
      {
        title: "Ventas Detalladas",
        route: "reportes-ventas-detalladas",
        slug: "reportes_ventas-ver",
      },
      {
        title: "Ventas Det. por Usuario",
        route: "reportes-ventas-detalladas-por-usuario",
        slug: "reportes_ventas-ver",
      },
      {
        title: "Ventas por Cliente",
        route: "reportes-ventas-detalladas-por-cliente",
        slug: "reportes_ventas-ver",
      },
      {
        title: "Ventas Totales Mensuales",
        route: "reportes-ventas-totales-mensuales",
        slug: "reportes_ventas-ver",
      },
      {
        title: "Ventas por Usuario",
        route: "reportes-ventas-por-usuario",
        slug: "reportes_usuario-ver",
      },
      {
        title: "Ventas por Area",
        route: "reportes-ventas-por-area",
        slug: "reportes_ventas-ver",
      },
    ],
  },
  {
    title: "Reportes de Util. Bruta",
    route: "reportes-utilidad",
    icon: "BarChart2Icon",
    slug: "reporte_utilidad-ver",
  },

  {
    header: "MODULO DE CONFIGURACION",
    slug: "sucursal-ver;sede-ver;funcion_permiso-ver;cargo-ver;usuario-ver",
  },

  {
    title: "Configuracion",
    icon: "SettingsIcon",
    slug: "configuracion-ver;sucursal-ver;sede-ver;funcion_permiso-ver;cargo-ver;usuario-ver;clasificador-ver;banco-ver;tipo_documento-ver;metodo_pago-ver;unidad-ver",
    children: [
      {
        title: "General",
        route: "configuracion-general",
        slug: "configuracion-ver",
      },
      {
        title: "Sucursal",
        route: "configuracion-sucursales",
        slug: "sucursal-ver",
      },
      {
        title: "Sedes",
        route: "configuracion-sedes",
        slug: "sede-ver",
      },
      {
        title: "Roles y Permisos",
        route: "configuracion-roles",
        slug: "funcion_permiso-ver",
      },
      {
        title: "Cargos",
        route: "configuracion-cargos",
        slug: "cargo-ver",
      },
      {
        title: "Usuarios",
        route: "configuracion-usuarios",
        slug: "usuario-ver",
      },
      {
        title: "Banco",
        route: "configuracion-bancos",
        slug: "banco-ver",
      },
      {
        title: "Clasificadores",
        route: "configuracion-clasificadores",
        slug: "clasificador-ver",
      },
      {
        title: "Tipos de Documentos",
        route: "configuracion-tipos-documentos",
        slug: "tipo_documento-ver",
      },
      {
        title: "Metodo Pagos",
        route: "configuracion-metodo-pagos",
        slug: "metodo_pago-ver",
      },
      {
        title: "Unidades",
        route: "configuracion-unidades",
        slug: "unidad-ver",
      },
    ],
  },

  {
    header: "MODULO DE CONFIGURACION SIN",
    slug: "configuracion_sin-sincronizar",
  },
  {
    title: "Configuracion SIN",
    icon: "SettingsIcon",
    slug: "configuracion_sin-sincronizar",
    children: [
      {
        title: "Sincronizacion",
        route: "configuracion-sin-sincronizar",
        slug: "configuracion_sin-sincronizar",
      },
      // {
      //   title: 'Eventos Significativos',
      //   route: 'configuracion-sin-eventos-significativos',
      //   // icon: 'SettingsIcon',
      //   slug: 'sucursal-ver'
      // },
      // {
      //   title: 'Sincronizar sucursales',
      //   route: '#',
      //   // icon: 'SettingsIcon',
      //   slug: 'sucursal-ver'
      // },
      // {
      //   title: 'Envios Masivos',
      //   route: '#',
      //   // icon: 'SettingsIcon',
      //   slug: 'sucursal-ver'
      // },
      // {
      //   title: 'Cafc.',
      //   route: '#',
      //   // icon: 'SettingsIcon',
      //   slug: 'sucursal-ver'
      // },
    ],
  },
];
