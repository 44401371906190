import axiosIns from "@/libs/axios";

const get = async ({ page, limit, searchQuery }) => {
  return await axiosIns.get(`sedes`)
  // return await axiosIns.get(`sedes?include=empresa;municipio;municipio.departamento`)
}

const store = async (data) => {
  return await axiosIns.post('sedes', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`sedes/${id}`, data)
}

const destroy = async (id) => {
  return await axiosIns.delete(`sedes/${id}`)
}

const getDepartamentos = async () => {
  return await axiosIns.get(`departamentos`)
}

const getSucursales = async () => {
  return await axiosIns.get(`sucursales`)
}

const getSedeUsuario = async () => {
  return await axiosIns.get(`sedes/usuario`)
}

export default {
  get,
  store,
  update,
  destroy,

  getSucursales,
  getDepartamentos,
  getSedeUsuario
}
