import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'

const { t } = useI18nUtils()
const { canViewVerticalNavMenuHeader } = useAclUtils()
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    const span = h('span', {}, t(this.item.header))
    const icon = h('feather-icon', { props: { icon: 'MoreHorizontalIcon', size: '18' } })
    if (canViewVerticalNavMenuHeader(this.item)) {
      if(this.canSee){
        return h('li', { class: 'navigation-header text-truncate' }, [span, icon])
      } 
    }
    return h()
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    canSee() {
      if (this.authenticated) {
        let cont;
        let getSlug = this.item.slug.split(";");
        for (let i = 0; i < getSlug.length; i++) {
          if (this.user?.permisos.indexOf(getSlug[i]) !== -1) {
            cont = true;
            break;
          } else {
            cont = false;
          }
        }
        return cont;
      }
    },
  },
}
